.cart-info{
    position: relative;
}
.cart-info-content{
    position: sticky;
    top: 0;
}
.cart-items{
    background-color: var(--light_level_3_color);
    margin-bottom: 10px;
    border-radius: 8px;
    position: relative;
}
.icon-delete{
    position: absolute;
    z-index: 10;
    right: 5px;
    top: 5px;
}
.cart-items img{
    border-radius: 8px;
    width: 150px;
    height: 150px;
    object-fit: contain;
}
 .title{
    font-weight: 600;
}
.available{
    border-top: 1px solid var(--light_level_2_color);
    margin-top: 10px;
    padding-top: 10px;
}
.cart-info-content{
    background-color: var(--light_level_3_color);
    margin-bottom: 10px;
    border-radius: 8px;
    width: 75%;
    margin: auto;
}
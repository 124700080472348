.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
  }
  
  .pagination li {
    margin-right: 5px;
  }
  
  .pagination-button {
    padding: 5px;
    border: 1px solid #f3f3f3;
    border-radius: 4px;
    background-color: #fff;
    color: var(--dark_level_2_color);
    cursor: pointer;
    transition: all 0.3s;
    cursor: pointer;

  }
  
  .pagination-button:hover {
    background-color: var(--dark_level_2_color);
    color: #fff;
    border: 1px solid var(--dark_level_2_color);
  }
  
  .pagination-button.disable {
    background-color: #f3f3f3;
    color: #bbb;
    cursor: not-allowed;
  }
  
  .pagination-button.active {
    background-color: var(--dark_level_2_color);
    color: white;
    font-weight: bold;
  }
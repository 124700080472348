.sidebar-items{
    width: 20%;
    border-right: 1px solid var(--dark_level_2_color);
}
.content-items{
    width: 80%;
}
.divider{
    height: 1px;
    width: 90%;
    background-color: var(--dark_level_2_color);

}
.root-category{
    overflow-x: scroll;
    width: 100%;
}
.root-category::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Edge */
  }
.category-div{
    background-color: var(--dark_level_2_color);
    padding: 5px;
    border-radius: 8px;
    color: var(--light_level_2_color);
    cursor: pointer;
    transition: 0.3s;
    white-space: nowrap;
    
}

.items-count{
    background-color: var(--light_level_2_color);
    color: var(--dark_level_2_color);
    padding: 3px;
    border-radius: 8px;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
}
.active-cat  .items-count{
    color: var(--light_level_2_color);
    background-color: var(--dark_level_2_color);
}
.category-div:hover{
    background: linear-gradient(90deg, #124E66, #212A31 ); /* Linear gradient colors */

}
.active-cat{
    background-color: white;
    border: 1px solid var(--dark_level_2_color);
    padding: 5px;
    border-radius: 8px;
    color: var(--dark_level_2_color);
    cursor: pointer;
    transition: 0.3s;
    white-space: nowrap;
}
/* Hide the default checkbox appearance */
.custom-checkbox {
    -webkit-appearance: none; /* For WebKit browsers */
    -moz-appearance: none;    /* For Firefox */
    appearance: none;         /* Standard */
    width: 20px;
    height: 20px;
    border: 2px solid var(--dark_level_2_color);
    border-radius: 50%; /* Make the box circular */
    background-color: #fff;
    cursor: pointer;
    transition: 0.3s;
  }
  
  /* Style for when the checkbox is checked */
  .custom-checkbox:checked {
    background-color: var(--dark_level_2_color);
    border-color: var(--dark_level_2_color);
    position: relative;
  }
  
  /* Add a point when checked */
  .custom-checkbox:checked::after {
    content: '';
    display: block;
    width: 7px;
    height: 7px;
    background-color: #fff; /* Color of the point */
    border-radius: 50%; /* Make it circular */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }


  .scroll-div-with-fetch{
    height: 145px;
    overflow-y: scroll;
  }

  .scroll-div-with-fetch::-webkit-scrollbar {
    width: 5px;
  }
  
  .scroll-div-with-fetch::-webkit-scrollbar-track {
    background-color:  var(--light_level_3_color);
    border-radius: 100px;
  }
  
  .scroll-div-with-fetch::-webkit-scrollbar-thumb {
    background-color: rgba(68, 67, 67, 0.484);
  border-radius: 100px;
  }
  .hint-text{
    transition: 0.4s;
  }
  .hint-text:hover{
    color: var(--warning-color);
  }
@media (max-width:1024px) {
    .sidebar-items{
        width: 30%;
        display: none;
        overflow-y: scroll;
      
    }
    .sidebar-items.open{
        position: fixed;
        top : 0;
        bottom : 0;
        background-color: white;
        z-index: 1000;
        display: block;
        padding: 5px;
        animation: open 0.3s forwards  1 ;
        
      
    }
    .sidebar-items .content-sidebar{
        animation: fadeIn 0.3s forwards 1;
    }
    .content-items{
        width: 100%;
    }
}
@media (max-width:500px) {
    .sidebar-items.open{
        width: 100%;
        /* height: 100vh; */
        animation: openFull 0.3s forwards  1 ;
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes openFull {
    from{
        width: 0;
    }
    to{
        width: 100%;
    }
}
@keyframes open {
    from{
        width: 0;
    }
    to{
        width: 35%;
    }
}
.categories .box{
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    transition: 0.5s;
}
.categories .box-sun{
    position: absolute;
    top:0;
    bottom: 0;
    right: 0;
    left:0;
    display: flex;
    z-index: 2;
    justify-content: center;
    align-items: center;
    color: black;
    text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.8); /* Adds a shadow for better readability */
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* border-radius: 8px; */

}
.categories .box-sun h2{
    opacity: 1;
}
.categories .box-sun:hover{
    cursor: pointer;
    
}

.categories img{
    filter: brightness(0.5);
    /* opacity: 0.6; */
    border-radius: 8px;
    width: 100%;
    min-height: 300px;
    max-height: 400px;
    /* width: 100; */
    object-fit: cover;
}

.services{
    background-color: var(--dark_level_1_color);
    color: var(--light_level_2_color);
    border-top-right-radius: 20rem;
    border-top-left-radius: 20rem;

}
.services-icon{
    font-size: var(--font-size-3xl);
}
.services p{
    font-size: var(--font-size-xs);
}
@media (max-width:768px) {
    .services{
        border-top-right-radius: 10rem;
        border-top-left-radius: 10rem;
    
    }  
}

.card-auth{
    background-color: var(--light_level_3_color);
    /* backdrop-filter: blur(10px);  */
}

.verification-code-div{
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    /* backdrop-filter: blur(10px);  */
}

.verification-code-div input{
    width:60px;
    height: 60px;
    border-radius: 8px;
    background-color: var(--dark_level_2_color);
    color: var(--light_level_3_color);
    text-align: center;
}
.timer{
    font-size: var(--font-size-xl);
}
.link-auth{
    font-size: var(--font-size-l);
    cursor: pointer;
    color: var(--warning-color);
    transition: 0.3s;
   
}
.link-auth:hover{
    background: linear-gradient(90deg, #124E66, #212A31 ); /* Linear gradient colors */
    background-clip: text; /* Clip the background to the text */
    -webkit-background-clip: text; /* For WebKit-based browsers */
    color: transparent; 
}
/* Mobile number With Code */
.mobile-input{
    position: relative;
    display: flex;
    height: 35px;
    border: 1px solid var(--dark_level_2_color);
    border-radius: 8px;
    padding: 5px;
}
.mobile-input input{
    border-radius: 8px;
    padding-left: .5rem;
    outline: unset;
    border: none;
    width: 100%;
    background-color: inherit;
  }
.mobile-input input::placeholder{
    color: var(--dark_level_2_color) ;
  }
.menu-countries{
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    max-height: 350px;
    padding:0 10px;
    /* border-radius: 7px; */
    overflow-y: scroll;
    background-color: white;
    z-index: 100;
}
.menu-countries img{
    border-radius: 8px;
}
.check-auth{
    width: 20px;
}
@media (max-width:400px) {
    .nav-btn{
        min-width: 50px !important;
        font-size: 12px !important;
    }    
}
.card-group{
    position: relative;
    margin-top: 10px;

}
.tools{
    position: fixed;
    width: 80px;
    padding: 10px;
    background-color: var(--light_level_3_color);
    border-radius: 8px;
    box-shadow: var(--shadow);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap : 10px;
    z-index: 100;
}
.content{
    margin-left: 90px;

}
.group-item{
    position: relative;

    padding: 10px;
    background-color: var(--light_level_3_color);
    box-shadow: var(--shadow);
    border-radius: 8px;
}
.items{
    position: relative;
    padding-left: 20px;
}
.items::before{
    content: "" ;
    position: absolute;
    left: 0;
    width: 8px;
    top: 0;
    bottom: 0;
    background-color: var(--main_color);
    z-index: 100;
    border-radius: 2px;
}
.collapse_item{
    overflow-y: scroll ;
    height: 155px;
    transition: height 0.5s ease-in-out; 
}
.collapse_item_open{
    overflow-y: scroll ;
    height: 400px;
    transition: height 0.5s ease-in-out; 
}

.tools-card{
    position: sticky;
    top: 20px;
    right: 10px;
    flex-direction: row;
    height: 30px;
    justify-content: end;
    z-index: 10;
}
.tools-card .icon-svg{
    background-color: var(--dark_level_2_color);
    color: var(--light_level_3_color);
    padding: 5px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.group-info{
    background-color: white;
    border-radius: 8px;
    margin-bottom: 10px;
}
.empty-div{
    background-color: var(--light_level_3_color);
}
/* Item View and Edit */
.content-item{
    position: relative;
    background-color: var(--light_level_3_color);
    min-height: 300px;
    border-radius: 8px;

}
.edit-icon{
    cursor: pointer;
    font-size: 25px;
    color: white;
    background-color: var(--dark_level_2_color);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.content-view{
    background: inherit;
    border: 1px solid black;
    border-radius: 8px;
    padding:6px 10px ;
}
.edit-photo{
    background-color: var(--dark_level_2_color);
    color: white;
    border-radius: 8px;
}
.image-item{
    position: relative;
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--light_level_1_color);
}
.image-item img{
    object-fit: contain;
    height: 150px;
}
@media (max-width:1024px) {
    .card-group{
        position: relative;
        margin-top: 10px;
    
    }
    .collapse_item{
        height: 140px;
        
    }
    .collapse_item_open{
        height: 400px;
    }
    .tools{
        position: fixed;
        width: fit-content;
        padding: 10px;
        background-color: var( --dark_level_2_color);
        color: var(--light_level_2_color);
        border-radius: 8px;
        box-shadow: var(--shadow);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap : 10px;
        right: 10px;
        
    }
    .tools svg{
        font-size: 20px !important;
    }
    .content{
        margin:0  10px;
        margin-top: 50px;
    }
    
}
@media (max-width:400px) {

    .collapse_item{
        height: 165 px;
    }
    .collapse_item_open{
        
        height: 500px;
    }

}
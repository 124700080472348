.products {
    min-height: 400px;
}
.products p{
    font-size: var( --font-size-xxs);
}
.card-product{
    background-color: var(--light_level_3_color);
    border-radius: 8px;
    position: relative;
    transition: 1s;
    overflow: hidden;
    width: 100%;
    height: 320px; /* Set a fixed height for all cards */

}
.card-product-img{
    background: url("../images/more.webp");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
    position: relative;
    transition: 1s;
    overflow: hidden;
    width: 100%;
    height: 320px; /* Set a fixed height for all cards */

}

.card-product .card-img{
    overflow: hidden;

}
.card-product .card-img img{
    transition: .3s;
    padding: 0.3rem;

}
.card-product .card-img:hover img{
    padding: 0rem;
    cursor: pointer;
    
}
.contact-us{
    position: absolute;
    /* background-color: #ac8aaa8c; */
    background-color: #d3d9d467;
    width: 100%;
    left: 0;
    top:-10px;
    display: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 0.3rem;
    top: 0;
    justify-content: space-around;
    font-size: var(--font-size-xl);
  
    
}
.status{
    position: absolute;
    width: 100%;
    left: 0;
    top:-10px;
    display: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 0.3rem;
    top: 0;
    justify-content: space-around;
    font-size: var(--font-size-xl);
}
.card-product:hover .contact-us{
    display: flex;
    animation-name: move;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}
.contact-us div:nth-child(1):hover , .contact-us div:nth-child(2):hover ,.contact-us div:nth-child(3):hover{
    cursor: pointer;
}
.old{
    text-decoration: line-through;
}
.badge{
    border-radius: 0.5rem;
    width: fit-content;
    font-size: var(--font-size-xxs);
    color: var(--light_level_3_color);
    padding: 0.3rem;
    text-transform: uppercase;
}
.is-opacity{
    animation: opacity 0.3s forwards  1 ;
}
.quantity{
    border-radius: 8px;
    background-color: var(--light_level_2_color);
    font-size: 13px;
    padding:0 10px;
    box-shadow: var(--shadow);
}
.icons-contact-item{
    width:40px;
    height: 40px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    background-color: var(--dark_level_1_color);
    color: white;
    font-size: 20px;
    cursor: pointer;
    margin-right: 20px;
}
@keyframes move {
    from{
        top:-10px;
    }to{
        top:0;
    }
    
}
@keyframes opacity {
    0%{
        opacity: 1;
    }50%{
        opacity: 0.5;
    }
    100%{
        opacity: 0;
    }
    
}
@media (max-width:400px) {
    .card-product{
        height: 330px; /* Set a fixed height for all cards */
    
    }
    .quantity{
        font-size: 9px;
    }
    .number-quantity{
        width: 1.5rem !important;
    }
    
}
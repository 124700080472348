.terms h2{
    font-weight: 800 !important;
}
.terms h4{
    padding: 10px 0;
}
.about-us h2{
    font-weight: 800 !important;
}
.about-us h4{
    padding: 10px 0;
}
.policy h2{
    font-weight: 800 !important;
}
.policy h4{
    padding: 10px 0;
}
.services-page h2{
    font-weight: 800 !important;
}
.services-page h4{
    padding: 10px 0;
}
.contact_us h2{
    font-weight: 800 !important;
}
.contact_us h4{
    padding: 10px 0;
}
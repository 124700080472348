@import url("https://fonts.googleapis.com/css2?family=Arima:wght@100..700&display=swap");
@import url("./styles/variable.css");
@import url("./styles/nav.css");
@import url("./styles/products.css");
@import url("./styles/services.css");
@import url("./styles/contact.css");
@import url("./styles/categories.css");
@import url("./styles/brands.css");
@import url("./styles/auth.css");
@import url("./styles/profile.css");
@import url("./styles/requests.css");
@import url("./styles/paginator.css");
@import url("./styles/dynamicPages.css");
@import url("./styles/item.css");
@import url("./styles/cart.css");


/* Tailwind requirments */
@tailwind base;
@tailwind components;
@tailwind utilities;


body{
  background-color: var(--light_level_2_color);
  font-family: "Arima", system-ui;
  font-optical-sizing: auto;
  color: var(--dark_level_1_color);
  font-weight: 500;
  
}
body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background-color:  var(--light_level_3_color);
  border-radius: 100px;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--light_level_1_color);
border-radius: 100px;
}
.div-scroll::-webkit-scrollbar {
  width: 5px;
}

.div-scroll::-webkit-scrollbar-track {
  background-color:  var(--light_level_3_color);
  border-radius: 100px;
}

.div-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(68, 67, 67, 0.484);
border-radius: 100px;
}
 
h1{
  font-size: var(--font-size-3xl);
}
h2{
  font-size: var(--font-size-xxl);
}
h3{
  font-size: var(--font-size-xl);
}
h4{
  font-size: var(--font-size-l);
}
h5{
  font-size: var(--font-size-s);
}
p{
  font-size: var(--font-size-s);
}
.text-error{
  color: var(--error-color);
}
.text-success{
  color: var(--success-color);
}
.text-warning{
  color: var(--warning-color);
}
.bg-error{
  background-color: var(--error-color);
}
.bg-success{
  background-color: var(--success-color);
}
.bg-warning{
  background-color: var(--warning-color);
}
.text-dark{
  color: var(--dark_level_2_color);
}
button{
  background-color: var(--dark_level_2_color) ;
  border: 1px solid var(--dark_level_2_color);
  color: var(--light_level_2_color);
  outline-style: none;
  padding: 0.4rem;
  border-radius: 8px;
  font-size: var(--font-size-s);
  font-weight: 500;
  transition: .3s;
  min-width: 100px;
  min-height: 40px;
}
button:hover{
  background-color: var(--light_level_2_color);
  border: 1px solid var(--dark_level_2_color);
  color:var(--dark_level_2_color)
}
button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
.button-outline{
  background-color: var(--light_level_2_color) ;
  border: 1px solid var(--dark_level_2_color);
  color: var(--dark_level_2_color);
  padding: 0.4rem;
  border-radius: 8px;
  font-size: var(--font-size-s);
  font-weight: 500;
  transition: .3s;
  min-width: 100px;
}
.button-outline:hover{
  background-color: var(--dark_level_1_color);
  border: 1px solid var(--light_level_2_color);
  color:var(--light_level_2_color)
}
.button-error{
  background-color: var(--error-color) ;
  border: 1px solid var(--error-color);
  color: var(--light_level_2_color);
  outline-style: none;
  padding: 0.4rem;
  border-radius: 8px;
  font-size: var(--font-size-s);
  font-weight: 500;
  transition: .3s;
  min-width: 100px;
  min-height: 40px;
}
.button-error:hover{
  background-color: white;
  border: 1px solid var(--error-color);
  color:var(--error-color)
}
/* Select */
select{
  display: flex;
  align-items: center;
  border: 1px solid var(--dark_level_2_color);
  border-radius: 8px;
  padding: 5px;
  height: 45px !important;
  background-color: inherit;
  width: 100%;
}
.css-13cymwt-control{
  display: flex;
  align-items: center;
  border: 1px solid var(--dark_level_2_color) !important;
  border-radius: 8px !important;
  height: 45px !important;
  padding: 5px;
  background-color: inherit !important;
  width: 100%;
  
}
.css-t3ipsp-control{
  background-color: inherit !important;
  border: 1px solid var(--dark_level_2_color) !important;
  box-shadow: unset !important;
  height: 45px !important;
}
.css-13cymwt-control svg{
  fill : var(--dark_level_2_color)
}
/*  Input */
.input{
  display: flex;
  align-items: center;
  border: 1.5px solid var(--dark_level_2_color);
  border-radius: 8px;
  padding: 5px;
  height: 100%;

}

.input .icon-input{
  border-radius: 8px;
  display: flex;
  font-size: var(--font-size-xl);
  color: var(--dark_level_2_color) ;
  justify-content: center;
  align-items: center;
}

.input input{
  border-radius: 8px;
  padding-left: .5rem;
  outline: unset;
  border: none;
  width: 100%;
  background-color: inherit;
}
.input:has(input:focus) {

  border: 1.5px solid var(--main_color);
  width: 100%;
  background-color: inherit;
}
.input:has(input:focus) .icon-input{
  border-radius: 8px;
  display: flex;
  font-size: var(--font-size-xl);
  color: var(--main_color) ;
  justify-content: center;
  align-items: center;
}
.input input::placeholder{
  color: var(--dark_level_2_color) ;
}
.input-light{
  display: flex;
  align-items: center;
  border: 1px solid var(--light_level_2_color);
  background-color: var(--light_level_2_color) ;
  border-radius: 8px;
  padding: 5px;
  height: 100%;

}
.input-light input{
  background-color: var(--light_level_2_color);
  border-radius: 8px;
  padding-left: .5rem;
  width: 100%;
  color: var(--dark_level_2_color) ;
  outline: unset;
  border: none;
}
.input-light input::placeholder{
  color: var(--dark_level_2_color) ;
}
.input-light  .icon-input{
  border-radius: 8px;
  display: flex;
  font-size: var(--font-size-xl);
  color: var(--dark_level_2_color) ;
  justify-content: center;
  align-items: center;
}
textarea{
  border-radius: 8px;
  padding:.3rem .5rem;
  background-color: var(--light_level_2_color);
  border: 1px solid var(--dark_level_1_color);
}
textarea::placeholder{
  color: var(--dark_level_2_color) ;
}
.footer-icon{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: var(--dark_level_2_color);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-l);
  transition: 0.4s;
}
.footer-icon:hover{
  background-color: var(--dark_level_1_color);
  color: white;
}
.footer .menu li{
  position: relative;
  width: fit-content;

}
.footer .menu li::before{
    content: "";
    position: absolute;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: var(--main_color);
    transition: .3s;
}
.footer .menu li:hover::before {
  width: 100%;
}
.footer .menu li:hover{
  color: var(--main_color);
  cursor: pointer;
}
.footer li{
  padding: 0.3rem 0;
}
.vibration{
  animation-name: vibration;
  animation-duration: 0.4s;
  animation-iteration-count: infinite;

}
.loading-div{
  background-color: rgb(70 68 68 / 50%);;
}
.animate-slide-in {
  animation: slide-in 0.3s ease-out;
  z-index: 1100;
}
.alert{
  position: fixed;
  box-shadow: var(--shadow);
  z-index: 900;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  animation: slide-up 0.3s ease-out;
  z-index: 1100;
}
@keyframes slide-up {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;

  }
}
/* Animation */
.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #748d925e;
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bounce {
  width: 16px;
  height: 16px;
  margin: 3px;
  background-color: #124E66;
  border-radius: 50%;
  display: inline-block;
  animation: bounce 1.4s infinite ease-in-out both;
}
.bounce-small{
  width: 6px;
  height: 6px;
  margin: 3px;
  background-color: var(--dark_level_2_color);
  border-radius: 50%;
  display: inline-block;
  animation: bounce 1.4s infinite ease-in-out both;
}

.bounce:nth-child(1) {
  animation-delay: -0.32s;
}

.bounce:nth-child(2) {
  animation-delay: -0.16s;
}
/* Slide Tab */
.Slide-Tab  ul {
  /* border: 1px solid var(--light_level_1_color); */
  border-radius: 8px;
  background-color: var(--light_level_3_color);
  padding:0.5rem 1rem;
  color: var( --dark_level_1_color );
  font-size: var(--font-size-s);
  box-shadow: var(--shadow-box);
}
.Slide-Tab ul li{
  cursor: pointer;
  border-radius: 8px;
  padding: 0.6rem;
}
.Slide-Tab ul li.active{
  color: black;
  background-color: var(--light_level_2_color);
}
/* For DataTable */
.bzRuyo{
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.hDPlLz{
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
/* loading */
.loader {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(0, 0, 0, 0.1); /* Light border */
  border-top: 3px solid  var(--light_level_2_color) ; /* Dark border for animation */
  border-radius: 50%; /* Makes it circular */
  animation: spin 1s linear infinite; /* Infinite spinning animation */
}
.loader-dark {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(0, 0, 0, 0.644); /* Light border */
  border-top: 3px solid  var(--light_level_2_color) ; /* Dark border for animation */
  border-radius: 50%; /* Makes it circular */
  animation: spin 1s linear infinite; /* Infinite spinning animation */
}
button:hover .loader{
  border-top: 3px solid var(--main_color);
}
.button-error .loader{
  width: 20px;
  height: 20px;
  border: 3px solid rgba(0, 0, 0, 0.1); /* Light border */
  border-top: 3px solid  white ; /* Dark border for animation */
  border-radius: 50%; /* Makes it circular */
  animation: spin 1s linear infinite; /* Infinite spinning animation */
}
.button-error:hover .loader{
  border-top: 3px solid var(--error-color);
}
.icon-div{
  background-color: var(--dark_level_2_color);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 5px;
}
.icon-success {
  background-color: var(--success-color);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 5px;
}
.icon-error{
  background-color: var(--error-color);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 5px;
}
/* to do */
.rdt_Pagination{
  overflow: hidden;

}
.dark-bg{
  background-color: var(--dark_level_2_color);
}
/* Confirm Card */
.confirm-card{
  background-color: var(--light_level_3_color);
}
/* Swiper override */
.swiper-button-prev, .swiper-button-next{
  background-color: var(--dark_level_2_color);
 
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  
}
.swiper-button-prev:after ,.swiper-button-next:after{
  font-size: 15px !important;
  font-weight: 600;
  color: white;
}

/* Keyframes for spinning animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
@keyframes vibration {
  0% { transform: translate(1px, 0); }
  20% { transform: translate(-1px, 0); }
  40% { transform: translate(1px, 0); }
  60% { transform: translate(-1px, 0); }
  80% { transform: translate(1px, 0); }
  100% { transform: translate(0, 0); }
}
@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@media (max-width:500px) {
  .alert{
    top: 50%;
    left:10px;
    right: 10px;
    transform: translate(0,-50%);
  }
  
}

.nav{
    position: fixed;
    width: 100%;
    z-index: 1000;
}
.nav.fixed{
    padding: 5px 10px !important;
    background-color: #a5bcc0f0 !important;
    animation: appendNav 0.5s 1  forwards ;
}
.nav.fixed img{
    height: 60px;
    object-fit: contain;
}

.nav.fixed .input{
    background-color: white;

}
.nav .menu-item{
    font-size: var(--font-size-s);
    transition: .3s;
    font-weight: 600;
    position: relative;
    white-space: nowrap;
    
}
.nav .menu-item::before{
    content: "";
    position: absolute;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: var(--main_color);
    transition: .3s;    
}
.active-link{
    font-size: var(--font-size-s);
    transition: .3s;
    font-weight: 600;
    position: relative;
    white-space: nowrap;
    color: var(--main_color);
    
}
.active-link::before{
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 3px;
    background-color: var(--main_color);
    transition: .3s; 
}
.menu-collapse{
    opacity: 0;
    position: absolute;
}
.root-menu-collapse{
    position: relative;


}
.menu-collapse-item:hover{
    color: var(--dark_level_2_color);
}
.menu-collapse-open{
    position: absolute;
    top: 30px;
    left: 0;
    width: 200px;
    background-color: var(--light_level_3_color);
    border-radius: 8px;
    transition: 0.3s opacity ease-in-out;
    z-index: 200;
    animation: openCollapse 0.2s forwards 1;
}
@keyframes openCollapse {
    from{
        opacity: 0;
        height: 0;
    }to{
        height: 170px;
        opacity: 1;
    }
    
}
.nav .menu-item:hover::before {
    width: 100%;
}
.nav .menu-item:hover{
    color: var(--main_color);
    cursor: pointer;
}
.menu-nav{

}
.avatar{
    border-radius: 50%;
    z-index:101 ;

}
.avatar .hr{
    background-color: var(--light_level_1_color);
    width: 100%;
    height: 2px;

}
.popup-avatar{
    width:250px;
    min-height: 100px;
    max-height: 300px;
    padding: 10px;
    top: 105%;
    border-radius: 8px;
    background-color: var(--light_level_3_color);
    box-shadow: var(--shadow);
    z-index: 100;
}
@keyframes appendNav {
    from{
        top :-100px
    }to{
        top :0
    }
    
}
@media (max-width:768px) {
    .menu-nav {
        position: fixed;
        box-shadow: var(--shadow);
        background-color: var(--pink-color);
        height: 100vh;
        top:0;
        left: 0;
        z-index: 100;
        font-size: var(--font-size-xl);
        animation: open 0.3s forwards  1 ;

    }
    .menu-nav .menu{
        display: block !important;
        opacity: 0; /* Ensure it starts at 0 */
        animation: fadeIn 0.3s forwards 1;
    }
    .menu-nav .menu .menu-item {
        display: flex;
        justify-content: center;
        padding: .3rem 0;
        width: max-content;
        margin: auto;
    }
    .menu-item{
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    .menu-collapse-open{
        position: static;
        width: fit-content;
        background-color: inherit;
    }
    .menu-collapse-open .menu-collapse-item {
        border-bottom: 1px solid var(--dark_level_2_color);
        display: flex;
        justify-content: center;
    }
}
.nav-icon{
    font-size: var(--font-size-l);
    width: 35px;
    color: white;
    background-color: var(--dark_level_2_color);
    height: 35px;
    border-radius: 50%;
    padding: 5px;
    
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes open {
    from{
        width: 0;
    }
    to{
        width: 40%;
    }
}
@media (max-width:500px) {
    .nav-icon{
        font-size: var(--font-size-s);
        width: 25px;
        color: white;
        background-color: var(--dark_level_2_color);
        height: 25px;
        border-radius: 50%;
        padding: 5px;
        
    }
    .nav img{
        height: 60px;
        object-fit: contain;
        width: unset;
    }
    
}

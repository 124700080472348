.contact{
    background-color: var(--dark_level_1_color);
    color: var(--light_level_2_color);
}
.contact .input-light{
    padding: 0.7rem;
    
}
.contact .input-light .icon-input{
    font-size: var(--font-size-xxl);
}

